<template>
  <div id="cryptosList-list">
    <div class="row overflow-x-auto">
      <div class="col-12 px-0">
        <table class="table mb-0">
          <thead class="bg-gray-th">
            <tr>
              <th class="border-0 sticky-col pl-125" scope="col">Name</th>
              <th class="border-0" scope="col">Price</th>
              <th class="border-0" scope="col"><span class="mr-2">ATH</span><HelpInfo :text="'<strong>All Time High:</strong> highest value reached.'"></HelpInfo></th>
              <th class="border-0" scope="col"><span class="mr-2">GP</span><HelpInfo :text="'<strong>Growth Potential:</strong> Calculation based on the relationship between the ATH and the current price.'"></HelpInfo></th>
              <th class="border-0" scope="col">Chain</th>
              <th class="border-0 white-space-nowrap" scope="col"><span class="mr-2">C. Supply</span><HelpInfo :text="'<strong>Circulating Supply:</strong> The amount of coins that are circulating in the market and are in public hands. It is analogous to the flowing shares in the stock market.'"></HelpInfo></th>
              <th class="border-0 white-space-nowrap" scope="col"><span class="mr-2">T. Supply</span><HelpInfo :text="'<strong>Total Supply:</strong> The maximum amount of coins that will ever exist in the lifetime of the cryptocurrency. It is analogous to the fully diluted shares in the stock market.'"></HelpInfo></th>
              <th class="border-0 white-space-nowrap" scope="col"><span class="mr-2">Holders</span><HelpInfo :text="'Number of wallets holding this cryptocurrency.'"></HelpInfo></th>
              <th class="border-0 white-space-nowrap pr-125" scope="col"><span class="mr-2">Last ATH</span><HelpInfo :text="'Days since the last time the cryptocurrency reached its ATH'"></HelpInfo></th>
            </tr>
          </thead>
          <tbody>
            <tr class="tr-clickable" @click="goToDetail(crypto)" v-for="(crypto,index) in listCrypto" v-bind:key="index">
              <td class=" sticky-col pl-125">
                <div class="d-flex align-items-center">
                  <span class="mr-2"><img class="float-left" width="28" :src="crypto.img"/></span>
                  <span class="mr-2 white-space-nowrap">{{crypto.name}}</span>
                  <span class="font-weight-bold">{{crypto.symbol}}</span>
                </div>
              </td>
              <td>
                <span v-if="crypto.last_price">$<span v-html="$fixPrice(crypto.last_price, crypto.decimals)"></span></span>
                <span v-else-if="crypto.last_price == 0" >
                  <span class="text-danger" title="It has not been possible to obtain the price">
                    <font-awesome-icon :icon="['fas', 'exclamation-triangle']" />
                  </span>
                </span>
                <span v-else class="text-center float-left w-100"><img src="@/assets/loader.svg" height="20" alt=""></span>
              </td>
              <td>${{crypto.ath}}</td>
              <td class="text-success">
                <span v-if="crypto.last_price">{{growthPotential(crypto.last_price, crypto.ath)}}%</span>
                <span v-else-if="crypto.last_price == 0" >
                  <span class="text-danger" title="It has not been possible to obtain the price">
                    <font-awesome-icon :icon="['fas', 'exclamation-triangle']" />
                  </span>
                </span>
                <span v-else class="text-center float-left w-100"><img src="@/assets/loader.svg" height="20" alt=""></span>
              </td>
              <td>
                <span v-for="(contract,index) in crypto.contracts" v-bind:key="index"><span v-if="index !== 0">,</span>{{contract.chain}}</span>
              </td>
              <td>{{$formatNumber(crypto.circulating_supply)}}</td>
              <td>{{$formatNumber(crypto.total_supply)}}</td>
              <td>{{$formatNumber(crypto.total_holders)}}</td>
              <td class="pr-125">{{daysUntilToday(crypto.last_ath_date)}}d ago</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import HelpInfo from '../../../../Shared/HelpInfo.vue';

export default {
  name: 'MainCryptosList',
  props: [
    'cryptosList',
  ],
  data() {
    return {
      listCrypto: []
    };
  },
  created(){
  },
  updated() {
    this.listCrypto = this.cryptosList;
  },
  components: {
    HelpInfo
  },
  methods: {
    growthPotential(price, ath) {
      let GP = ((price * 100) / ath) - 100;
      return GP.toFixed(2);
    },
    daysUntilToday(date){
      let fechaInicio = new Date(date).getTime();
      let fechaFin    = new Date().getTime();

      let diff = fechaFin - fechaInicio;
      diff = diff/(1000*60*60*24)
      return (diff.toFixed(0) );
    },
    goToDetail(crypto){
      this.$router.push({ name: 'crypto-detail', params: { address: crypto.contracts[0].id, chain: crypto.contracts[0].chain }, query: { symbol: crypto.symbol}});
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  #cryptosList-list{
    table{
      tr{
        border-bottom: 2px solid #191C14;
        &:last-child{
          border: 0;
        }
      }
      th{
        color: #747a83;
        font-weight: normal;
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
      }
      td{
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
      }
    }
  }
</style>
