<template>
  <div class="d-inline-block">
    <a tabindex="0" role="button" data-container="body" data-toggle="popover" data-trigger="focus" data-placement="right" data-html=true :data-content="text">
      <font-awesome-icon class="text-primary" :icon="['far', 'question-circle']" />
    </a>
  </div>
</template>
<script>

import $ from 'jquery'

export default {
  name: 'helpInfo',
  props: [
    'text',
  ],
  data() {
    return {
    }
  },
  mounted() {
    $('[data-toggle="popover"]').popover();
  },
  components: {
  },
  methods: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
